<script>
import moment from 'moment';
moment.locale('pt-BR');

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import SaleService from '@/services/sale'

export default {
  page: {
    title: "Pedidos",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pedidos",
      sales: [],
      userRole: 'store',

      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      fields: [
        { key: 'code', sortable: false, label: '#' },
        { key: 'userName', sortable: true, label: 'Cliente' },
        { key: 'storeName', sortable: true, label: 'Loja', requiresAdmin: true },
        { key: 'date', sortable: true, label: 'Data/Hora', formatter: (value) => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss');
        } },
        { key: 'statusDescription', sortable: true, label: 'Status' },
        { key: 'paymentDescription', sortable: true, label: 'Pagamento' },
        { key: 'deliveryDescription', sortable: true, label: 'Entrega' },
        { key: 'totalValue', sortable: true, label: 'Valor do Pedido', formatter: (value) => {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } },
        { key: 'actions', label: 'Ações' },
      ],
    };
  },
  computed: {
    rows() {
      return this.sales.length;
    },
    computedFields() {
      if (this.userRole !== 'admin')
        return this.fields.filter(field => !field.requiresAdmin);
      else
        return this.fields;
    }
  },
  mounted() {
    this.loadSales();

    this.$store.dispatch('USER_DATA').then((res) => {
      this.userRole = res.role;
    })
  },
  sockets: {
    // newSale() {
    //   // eslint-disable-next-line no-console
    //   console.log('socket recebido');

    //   this.load(1, true);
    //   this.$toast.open('Novo Pedido');
    // }
  },
  methods: {
    loadSales(showLoading = true, page = 1) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      let user = '';
      if (this.$route.query && this.$route.query.user) {
        user = this.$route.query.user;
      }

      SaleService.getStoreSales(page, user)
        .then(res => {
          if (loader) {
            loader.hide();
          }

          this.sales = res.data.sales;
          this.count = res.data.count;
          this.currentCount = res.data.currentCount;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.perPage = res.data.perPage;
          this.startIndex = res.data.startIndex;
          this.endIndex = res.data.endIndex;
        })
      .catch(() => {
        if (loader) {
          loader.hide();
        }
      })
    },
    onPageChange() {
      this.loadSales(true, this.currentPage);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="row mb-2">
              <div class="col-6">
                 <div class="position-relative search-input search-box">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        placeholder="Procurar por código ou descrição..."
                      />

                      <button type="submit" class="btn btn-rounded btn-outline-primary ml-2">
                        Pesquisar
                      </button>
                    </form>
                  </div>
              </div>
              <div class="col-6">
                <div class="text-right">
                  <router-link to="/products/edit">
                    <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2">
                      <i class="mdi mdi-plus mr-1"></i> Novo Produto
                    </button>
                  </router-link>
                </div>
              </div>
            </div> -->

            <div class="table-responsive mb-0">
              <b-table
                show-empty
                emptyText="Nenhum pedido para exibir"
                :items="sales"
                :fields="computedFields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(userName)="row">
                  {{row.item.userName}}
                  <span v-if="row.item.saleCountStore === 0" class="badge badge-pill badge-soft-warning font-size-10 pt-1">Primeira Compra</span>
                </template>

                <template v-slot:cell(statusDescription)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-danger': row.item.status === 'canceled',
                      'badge-soft-warning': row.item.status === 'awaitingPayment',
                      'badge-soft-dark': row.item.status === 'done',
                    }"
                  >{{row.item.statusDescription}}</span>
                </template>   

                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <router-link :to="'/store/sales/detail/' + row.item._id">
                      <b-dropdown-item href="javascript: void(0);">
                        <i class="fas fa-shopping-cart text-warning mr-2 mt-2"></i>
                        Detalhes do Pedido
                      </b-dropdown-item>
                    </router-link>
                    <router-link :to="`/chat/${row.item.user._id}`" v-if="userRole !== 'admin'">
                      <b-dropdown-item href="javascript: void(0);">
                        <i class="bx bx-support text-primary mr-2 mt-2"></i>
                        Falar com Cliente
                      </b-dropdown-item>
                    </router-link>
                    
                    <!-- <b-dropdown-item @click="copyProduct(row.item)">
                      <i class="fas fa-copy text-info mr-2 mt-2"></i>
                      Copiar Produto
                    </b-dropdown-item> -->
                  </b-dropdown>
                </template>
              </b-table>
            </div>
            
             <div class="row justify-content-md-between align-items-md-center">
              <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>

              <div class="col-xl-5">
                <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                  <b-pagination
                    v-model="currentPage" 
                    :total-rows="count"
                    :per-page="perPage"
                    @input="onPageChange"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
